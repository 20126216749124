// Reset CSS
* {
  padding: 0;
  margin: 0;
}

input {
  outline: none;
}

body {
  background: #000;
  font-family: "Inter", sans-serif;
  font-size: 16px;
}

ol,
ul {
  list-style: none;
}

img {
  vertical-align: top;
  width: 100%;
  border: none;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

input {
  border: none;
}

a {
  cursor: pointer;
}

@keyframes move1 {
  100% {
    transform: translateY(30px);
  }
}

@keyframes move2 {
  100% {
    transform: translateX(20px);
  }
}

@keyframes move3 {
  100% {
    transform: translateX(-30px);
  }
}

@keyframes move4 {
  100% {
    transform: translateY(-30px);
  }
}

@keyframes scale1 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scale2 {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(1.4);
  }
  75% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  100% {
    transform: rotateY(150deg);
  }
}

// .rotate {
//   animation: rotate 2s linear 5 alternate;
// }

// Style
.App {
  overflow: hidden;
  .container {
    width: 80%;
    padding: 0 24px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    @media screen and (max-width: 1300px) {
      width: 90%;
    }
  }
  .page-header {
    padding-top: 26px;
    position: relative;
    z-index: 999;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 200px;
      }
      .header-menu {
        ul {
          display: flex;
          align-items: center;
          color: #fff;
          text-align: center;
          font-weight: 500;
          gap: 32px;
        }
      }
      .header-right {
        color: #fff;
        font-weight: 500;
        display: flex;
        align-items: center;
      }
    }
  }
  .page-main {
    .section-intro {
      position: relative;
      transition: all 0.5s linear;
      &:hover {
        .intro-p {
          &-1 {
            transform: translateX(15px);
          }
          &-2 {
            transform: translateY(15px);
          }
          &-4 {
            transform: translateX(-15px);
          }
          &-9 {
            transform: translateX(15px);
          }
          &-10 {
            transform: translateY(15px);
          }
        }
      }
      .container {
        position: relative;
        z-index: 9999;
        display: flex;
        // justify-content: space-between;
        gap: 150px;
        padding-top: 115px;
        .left-content {
          position: relative;
          z-index: 999;
          max-width: 800px;
          .title {
            // color: #fff;
            font-size: 70px;
            font-weight: 800;
            padding-bottom: 25px;
            background: -webkit-linear-gradient(#f5d1ff, #fff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .content {
            color: rgba(255, 255, 255, 0.8);
            font-size: 18px;
            font-weight: 400;
            line-height: 40px;
          }
        }
        .right-content {
          position: relative;
          z-index: 999;
          img {
            width: 289px;
            position: relative;
            z-index: 999;
          }
        }
      }
      .intro-p {
        position: absolute;
        transition: all 0.5s linear;
        &-main {
          right: 5%;
          z-index: 99;
        }
        &-b {
          &-1 {
            top: 0;
          }
          &-2 {
            top: 0;
            left: 40%;
            z-index: 10;
            animation: scale1 5s linear infinite;
          }
          &-3 {
            top: -50%;
            left: 30%;
            z-index: 10;
          }
          &-4 {
            top: -10%;
            right: 0;
            // z-index: 1;
            &-3 {
              right: -5%;
              animation: scale2 4s linear infinite;
            }
          }
          &-5 {
            top: -50%;
            left: 50%;
            z-index: 10;
          }
        }
        &-1 {
          bottom: 12%;
          left: 10%;
          animation: move1 1s ease-in 1 alternate-reverse;
        }
        &-2 {
          bottom: 25%;
          left: 23%;
          animation: move2 1s ease-in 1 alternate-reverse;
        }
        &-3 {
          bottom: 27%;
          left: 35%;
          animation: move3 1s ease-in 1 alternate-reverse;
        }
        &-4 {
          bottom: 55%;
          left: 33%;
          animation: move2 1s ease-in 1 alternate-reverse;
        }
        &-5 {
          top: -10%;
          right: 3%;
          animation: move4 1s ease-in 1 alternate-reverse;
        }
        &-6 {
          top: 10%;
          right: 13%;
          animation: move4 1s ease-in 1 alternate-reverse;
        }
        &-7 {
          top: 50%;
          right: 20%;
          z-index: 999;
          animation: move1 1s ease-in 1 alternate-reverse;
        }
        &-8 {
          top: 45%;
          right: 10%;
          animation: move2 1s ease-in 1 alternate-reverse;
        }
        &-9 {
          top: 68%;
          right: 10%;
          z-index: 999;
          animation: move3 1s ease-in 1 alternate-reverse;
        }
        &-10 {
          top: 85%;
          right: 13%;
          animation: move1 1s ease-in 1 alternate-reverse;
        }
      }
    }
    .section-vision {
      .container {
        position: relative;
        padding-top: 55px;
        display: flex;
        gap: 134px;
        .left-content {
          .content-top {
            color: rgba(255, 255, 255, 0.5);
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.8px;
            letter-spacing: 1.28px;
            text-transform: uppercase;
          }
          .title {
            padding-top: 24px;
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 60px;
          }
          .content-bottom {
            padding-top: 27px;
            color: rgba(255, 255, 255, 0.82);
            font-size: 18px;
            font-style: normal;
            font-weight: 200;
            line-height: 34.023px;
            letter-spacing: -0.156px;
          }
          .actions {
            padding-top: 43px;
            display: flex;
            align-items: center;
            gap: 75px;
            .btn-left,
            .btn-right {
              padding: 24px 20px;
              display: flex;
              align-items: center;
              gap: 17px;
            }
            .btn-left {
              border-radius: 10px;
              background: #fff;
              box-shadow: 0px 5px 11px 0px rgba(0, 3, 66, 0.07),
                0px 2px 4px 0px rgba(0, 0, 0, 0.04),
                0px 0px 1px 0px rgba(0, 0, 0, 0.04);
              color: #000;
              font-size: 20.328px;
              font-style: normal;
              font-weight: 600;
              line-height: 27.449px;
              letter-spacing: -0.355px;
              transition: all 0.3s linear;
              &:hover {
                opacity: 0.8;
              }
            }
            .btn-right {
              color: rgba(255, 255, 255, 0.5);
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 27.449px;
              letter-spacing: -0.355px;
              transition: all 0.3s linear;
              border-radius: 10px;

              &:hover {
                background-color: #fff;
                color: #000;
              }
            }
          }
        }
        .right-content {
          .content-top,
          .content-bottom {
            padding: 48px;
            h3 {
              color: #fff;
              font-size: 76.76px;
              font-style: normal;
              font-weight: 800;
              line-height: 100.55px;
              letter-spacing: -4.937px;
            }
            p {
              color: rgba(255, 255, 255, 0.82);
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 20.8px;
            }
          }
        }
      }
    }
    .section-ecosystem {
      position: relative;
      .container {
        padding-top: 173px;
        .section-title {
          color: rgba(255, 255, 255, 0.5);
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20.8px;
          letter-spacing: 1.28px;
          text-transform: uppercase;
        }
        .content-top {
          display: flex;
          align-items: center;
          padding-top: 32px;
          h3 {
            color: #fff;
            text-align: right;
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 60px;
            border-right: 1px solid #fff;
            padding-right: 16px;
          }
          p {
            color: rgba(255, 255, 255, 0.82);
            font-size: 21.328px;
            font-style: normal;
            font-weight: 200;
            line-height: 34.023px;
            letter-spacing: -0.156px;
            padding-left: 16px;
          }
        }
        .content-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 235px;
          padding-top: 87px;
          &-left {
            margin-top: 200px;
            width: 300px;
            flex-shrink: 0;
          }
          .core-boxes {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 64px;

            .box {
              .box-img {
                width: 92px;
                img {
                  width: 100%;
                }
                &.custom-size {
                  margin: -28px;
                  width: 130px;
                }
              }
              h3 {
                padding-top: 30px;
                color: #fff;
                font-size: 20.328px;
                font-style: normal;
                font-weight: 700;
                line-height: 27.449px;
                letter-spacing: -0.355px;
              }
              p {
                padding-top: 20px;
                color: rgba(255, 255, 255, 0.82);
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 26px;
              }
            }
          }
        }
        .action {
          display: flex;
          justify-content: flex-end;
          padding-top: 89px;
          .btn-trade {
            border-radius: 10.02px;
            background: #fff;
            box-shadow: 0px 5px 11px 0px rgba(0, 3, 66, 0.07),
              0px 2px 4px 0px rgba(0, 0, 0, 0.04),
              0px 0px 1px 0px rgba(0, 0, 0, 0.04);
            color: #000;
            font-size: 20.328px;
            font-style: normal;
            font-weight: 600;
            line-height: 27.449px;
            letter-spacing: -0.355px;
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 24px 64px;
            transition: all 0.3s linear;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      .ecosystem-p {
        position: absolute;
        &-main {
          left: -10%;
          top: 20%;
        }
        &-1 {
          top: 35%;
          left: 15%;
        }
        &-2 {
          top: 55%;
          left: 30%;
        }
        &-b {
          &-1 {
            top: 10%;
          }
          &-2 {
            top: 15%;
            left: -30%;
          }
          &-3 {
            top: 30%;
            left: -5%;
          }
          &-4 {
            top: 54%;
            left: 7.5%;
            animation: scale1 5s linear infinite alternate-reverse;
          }
          &-5 {
            bottom: -50%;
            left: -10%;
          }
        }
      }
    }
    .section-features {
      .container {
        padding-top: 122px;
        .section-top {
          h3 {
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 79.119px;
            color: #fff;
          }
          p {
            display: flex;
            align-items: center;
            gap: 17px;
            color: rgba(255, 255, 255, 0.5);
            font-size: 20.328px;
            font-style: normal;
            font-weight: 600;
            line-height: 27.449px;
            letter-spacing: -0.355px;
            padding-top: 19px;
          }
        }
        .section-bottom {
          padding-top: 24px;
          display: flex;
          gap: 40px;
          .content-left {
            width: 40%;
            h3 {
              margin-top: -150px;
              font-size: 50.512px;
              font-style: normal;
              font-weight: 800;
              line-height: 61.416px;
              letter-spacing: -2.684px;
              padding-right: 98px;
              padding-bottom: 25px;
            }
            .box-img {
              display: flex;
              align-items: center;
              margin: 0 -32px;
              &-1 {
                transition: all 0.5s linear;
              }
              &-2 {
                margin-left: -80px;
                margin-bottom: 50px;
                position: relative;
                transition: all 0.5s linear;
              }
            }
          }
          .content-right {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 23px;
            h3 {
              font-size: 37.904px;
              font-style: normal;
              font-weight: 800;
              line-height: 47.251px;
              letter-spacing: -1.417px;
              padding: 65px 0;
            }
            .box-exchange {
              position: relative;
              &-img,
              &-img-hover {
                width: 400px;
                position: absolute;
                top: 0;
                right: 0;
              }
              .box-exchange-img-not-hover {
                opacity: 1;
                transition: all 0.5s linear;
              }
              .box-exchange-img-hover {
                opacity: 0;
                transition: all 0.5s linear;
                transform: translateX(200px);
              }
            }
            .box-exchange.box-feature {
              height: calc(50% - 96px);
            }
            .box-row {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 31px;
            }
          }
        }
      }
    }
    .section-values {
      padding-top: 131px;
      position: relative;
      .container {
        .section-title {
          color: rgba(255, 255, 255, 0.5);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20.8px;
          letter-spacing: 1.28px;
          text-transform: uppercase;
        }
        .row {
          gap: 136px;
        }
        .left-content {
          width: 50%;
          h3 {
            background: linear-gradient(229deg, #ffd8b4 4.17%, #ffc8f0 45.71%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 79.119px;
          }
          .content-1 {
            padding: 47px 0 19px;
          }
          p {
            color: rgba(255, 255, 255, 0.82);
            font-size: 21.328px;
            font-style: normal;
            font-weight: 200;
            line-height: 34.023px;
            letter-spacing: -0.156px;
          }
        }
        .info-values {
          padding-top: 128px;
          .info-title {
            color: rgba(255, 255, 255, 0.5);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20.8px;
            letter-spacing: 1.28px;
            text-transform: uppercase;
          }
          .value {
            color: #fff;
            font-size: 114.648px;
            font-style: normal;
            font-weight: 900;
            line-height: 125.314px;
            letter-spacing: -6.581px;
            gap: 63px;
            flex-shrink: 0;
            span {
              color: #fff;
              font-size: 27.432px;
              font-style: normal;
              font-weight: 700;
              line-height: 36.1px;
              letter-spacing: -0.726px;
            }
          }
          .note {
            color: rgba(255, 255, 255, 0.82);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20.8px;
            max-width: 295px;
          }
        }
        .right-content {
          padding-top: 110px;
          .info-values {
            &:last-child {
              padding-top: 99px;
            }
          }
        }
      }

      .value-p {
        position: absolute;
        &-1 {
          right: 35%;
          z-index: 99;
        }
        &-2 {
          top: -60%;
          right: -35%;
        }
        &-3 {
          top: 40%;
          right: 10%;
        }
      }
    }
    .section-journey {
      position: relative;
      padding: 168px 0 209px;
      .img-section-journey {
        left: 40%;
        transform: translateX(-50%);
        position: absolute;
        top: -10%;
        z-index: 1;
        &-2 {
          position: absolute;
          left: 10%;
          z-index: 1;
        }
        &-3 {
          position: absolute;
          top: -15%;
          right: -20%;
          z-index: 1;
        }
        &-4 {
          position: absolute;
          top: 20%;
          left: 30%;
          z-index: 0;
        }
      }

      .img-earth-journey {
        position: absolute;
        left: 10%;
        bottom: 10%;
      }
      .container {
        z-index: 1;
        position: relative;
        .section-title {
          color: #fff;
          font-size: 60px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;
          padding-bottom: 45px;
          text-align: center;
        }
        .journey-list {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 32px;
          .journey-item {
            color: #fff;
            width: 341px;
            height: 398px;
            padding: 32px;
            border-radius: 20px;
            background: #171717;
            transition: all 0.5s linear;
            &-hover {
              &-1,
              &-2,
              &-3 {
                svg {
                  transition: all 0.5s linear;
                  path {
                    transition: all 0.5s linear;
                  }
                }
              }
              &-2 {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                width: 25px;
              }
            }
            &:hover {
              background-color: #fff;
              color: #000;
              .title {
                color: #000 !important;
              }
              .journey-item-hover-1,
              .journey-item-hover-2,
              .journey-item-hover-3 {
                svg {
                  path {
                    stroke: #000;
                  }
                }
              }
              .journey-item-hover-1 svg {
                transform: rotate(180deg);
              }
              .journey-item-hover-3 svg {
                transform: rotateY(180deg);
              }
              .journey-item-hover-2 svg:first-child {
                transform: translateX(20px);
              }
              .journey-item-hover-2 svg:last-child {
                transform: rotate(30deg);
              }
            }
            .title {
              color: rgba(255, 255, 255, 0.5);
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 16.995px;
              letter-spacing: 1.04px;
              text-transform: uppercase;
            }
            .column-between {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: 100%;
            }
            .sub-title {
              padding-top: 63px;
              font-size: 27.432px;
              font-style: normal;
              font-weight: 700;
              line-height: 36.1px;
              letter-spacing: -0.726px;
              svg {
                padding-left: 17px;
                rotate: -45deg;
              }
            }
            .content {
              padding-top: 16px;
              // color: #fff;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 26px;
            }
            img {
              width: 34px;
            }
          }
        }
      }
    }
    .section-community {
      .container {
        display: flex;
        justify-content: center;
        gap: 157px;
        .left-content {
          h3 {
            color: #fff;
            font-size: 60px;
            font-style: normal;
            font-weight: 800;
            line-height: 79.119px;
            max-width: 355px;
          }
          p {
            max-width: 415px;
            padding-top: 20px;
            color: rgba(255, 255, 255, 0.82);
            font-size: 18px;
            font-style: normal;
            font-weight: 200;
            line-height: 34.023px;
            letter-spacing: -0.156px;
          }
        }
        .right-content {
          ul {
            display: flex;
            flex-direction: column;
            gap: 68px;
            li {
              .content {
                padding-left: 32px;
                h4 {
                  color: #fff;
                  font-size: 20.328px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 27.449px;
                  letter-spacing: -0.355px;
                  svg {
                    padding-left: 6px;
                    rotate: -45deg;
                  }
                }
                p {
                  max-width: 372px;
                  padding-top: 12px;
                  color: rgba(255, 255, 255, 0.5);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 26px;
                }
              }
            }
          }
        }
      }
    }
    .section-enter-ecosystem {
      padding-top: 108px;
      padding-bottom: 108px;
      background: url("./assets/images/img-bg-section-enter-eco.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        h3 {
          color: #fff;
          text-align: center;
          font-size: 60px;
          font-style: normal;
          font-weight: 800;
          line-height: 79.119px;
        }
        p {
          color: rgba(255, 255, 255, 0.82);
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 200;
          line-height: 34.023px;
          letter-spacing: -0.156px;
        }
        .list-system {
          display: flex;
          justify-content: center;
          gap: 32px;
          padding-top: 82px;
          .item {
            position: relative;
            overflow: hidden;
            padding: 32px;
            width: 341px;
            height: 466px;
            border-radius: 20px;
            background: #171717;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            transition: all 0.3s linear;
            color: #fff;
            &:hover .img-earn {
              transform: scale(1.2);
            }
            &-2 {
              &:hover {
                // background-color: #fff;
                // color: #000;
                .icon-coin3 {
                  transform: rotate(0);
                }
                .icon-coin1 {
                  transform: rotate(0) translateX(20px);
                }
                .icon-coin2 {
                  transform: rotate(0) translateX(50px);
                }
              }
            }
            .img-earn {
              position: absolute;
              top: 0;
              left: 0;
              transition: all 0.3s linear;
            }
            .icon-earn {
              position: absolute;
              width: 158px;
              right: 0;
              bottom: 150px;
            }
            .icon-coin1,
            .icon-coin2,
            .icon-coin3 {
              transition: all 0.5s linear;
              position: absolute;
              width: 236px;
            }
            .icon-coin1 {
              top: 0;
              left: 20%;
              transform: rotate(20deg);
              z-index: 1;
            }
            .icon-coin2 {
              top: 55px;
              z-index: 3;
              transform: rotate(-20deg);
            }
            .icon-coin3 {
              top: 96px;
              left: 114px;
              z-index: 2;
              transform: rotate(20deg);
            }
            .title {
              z-index: 1;
              font-size: 13px;
              font-style: normal;
              font-weight: 600;
              line-height: 16.995px;
              letter-spacing: 1.04px;
              text-transform: uppercase;
              text-align: left;
            }
            .sub-title {
              color: rgba(255, 255, 255, 0.7);
              z-index: 1;
              max-width: 215px;
              font-size: 27.432px;
              font-style: normal;
              font-weight: 700;
              line-height: 36.1px;
              letter-spacing: -0.726px;
              text-align: left;
              padding-top: 16px;
              svg {
                padding-left: 4px;
              }
            }
            .des {
              z-index: 1;
              padding-top: 16px;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 26px;
              text-align: left;
              max-width: 250px;
            }
            &.item-discover {
              // background: url("./assets/images/img-bg-discover.svg");
              // background-repeat: no-repeat;
              // background-size: cover;
              // background-position: top center;
              transition: all 0.5s linear;
              &:hover {
                .img-earth1 {
                  transform: translateY(-20px);
                }
                .item-discover-bg {
                  transform: scale(1.2);
                }
                .img-earth2 {
                  transform: translateX(-20px);
                }
                .img-bone {
                  transform: translate(-20px, -20px);
                }
              }
              .img-earth1,
              .img-bone,
              .img-earth2,
              .item-discover-bg {
                position: absolute;
                transition: all 0.5s linear;
              }
              .img-earth1 {
                left: 0;
                bottom: 133px;
                width: 234px;
              }
              .item-discover-bg {
                top: 0;
                left: 0;
                right: 0;
              }
              .img-bone {
                width: 41px;
                right: 24px;
                bottom: 290px;
              }
              .img-earth2 {
                width: 33px;
                bottom: 390px;
                right: 20px;
              }
            }
          }
        }
      }
    }
    .section-become-partner {
      padding: 136px 0 90px;
      .container {
        gap: 128px;
        .left-content {
          h3 {
            color: #fff;
            font-size: 27.432px;
            font-style: normal;
            font-weight: 700;
            line-height: 36.1px;
            letter-spacing: -0.726px;
          }
          p {
            color: rgba(255, 255, 255, 0.5);
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: 21.125px;
            letter-spacing: 0.054px;
          }
        }
        .right-content {
          position: relative;
          input {
            width: 373px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.1);
            padding: 16px 24px;
            color: #666;
            font-size: 21.328px;
            font-style: normal;
            font-weight: 200;
            line-height: normal;
            letter-spacing: -0.156px;
          }
          svg {
            position: absolute;
            right: 21px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
  .page-footer {
    .container {
      padding-top: 64px;
      border-top: 1px solid #292929;
      .main-footer {
        display: flex;
        justify-content: center;
        gap: 84px;
        padding-bottom: 98px;
        ul {
          li {
            color: rgba(255, 255, 255, 0.5);
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 26px;
            &:not(:last-child) {
              padding-bottom: 20px;
            }
            &:first-child {
              color: #fff;
              font-size: 15px;
              font-style: normal;
              font-weight: 600;
              line-height: 20.8px;
              letter-spacing: -0.267px;
            }
          }
        }
      }
      .footer-bottom {
        border-top: 1px solid #292929;
        display: flex;
        justify-content: space-between;
        padding: 42px 150px;
        color: rgba(255, 255, 255, 0.7);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        ul {
          display: flex;
          gap: 16px;
        }
      }
    }
  }
}
.row {
  display: flex;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.box-feature {
  overflow: hidden;
  border-radius: 20px;
  background: #171717;
  padding: 32px 32px 64px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.5s linear;
  &:hover {
    background-color: #fff;
    color: #000;
    h3 {
      color: #000;
    }
    .box-img-1 {
      transform: translateY(-40px);
    }
    .box-img-2 {
      transform: translateX(20px);
    }
    .box-exchange-img-not-hover {
      opacity: 0 !important;
      transform: translateX(200px);
    }
    .box-exchange-img-hover {
      opacity: 1 !important;
      transform: translateX(0) !important;
    }
  }
  h3 {
    color: #fff;
    transition: all 0.5s linear;
  }
  .box-title {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16.995px;
    letter-spacing: 1.04px;
    text-transform: uppercase;
  }
  .text-bottom {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 20.8px;
  }
}
